import React from 'react'
import MetaDecorator from '../../components/Utils/Metadata'

import {  Navbar, Footer} from '../../components'



const contactanos  = require ("./Data")
const Contactanos = () => {

  return (
    
    <>
    <MetaDecorator title={contactanos.Metadata.pagetitle} description={contactanos.Metadata.pagedescription}/>
    < Navbar />
    <Footer />
    </>
  )
}

export default Contactanos