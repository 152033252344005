import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";



const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index : 9999;
`;
const ModalContainer1 = styled(motion.div)`
  width: 90%;
  height: 95%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  z-index : 9999;
  overflow-y: auto;
`;
const CloseButton = styled.div
`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
  color: ${({ theme }) => theme.headigh4};
  display: flex;
  justify-content: center;
  align-items: center;
  

`;
export const ModalContentvtkcontrol = styled.div`
padding : 40px;
height: 100%;
width: 100%;
display: grid;
background: ${({ theme }) => theme.secwhite};


  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  grid-template-areas:
  "area1 area1 area2 area2 area2 area2"
  "area1 area1 area2 area2 area2 area2"
  "area1 area1 area2 area2 area2 area2"
  "area1 area1 area3 area3 area4 area4"
  "area1 area1 area3 area3 area4 area4";

  @media screen and (max-width: 768px) {
    padding-bottom: 2px;
    max-width: 100%;
    flex-basis: 100%;
    justify-content: center;
    width : auto;
    background: ${({ theme }) => theme.secwhite};
    overflow-y: auto;
    display: block; 
    padding : 5px;
  }
`;

const modalVariant = {
  initial: { opacity: 0 },
  isOpen: { opacity: 1 },
  exit: { opacity: 0 }
};
const containerVariant = {
  initial: { top: "-50%", transition: { type: "spring" } },
  isOpen: { top: "50%" },
  exit: { top: "-50%" }
};
const Modalvtkcontrol = ({ handleClose, children, isOpen2 }) => {
  return (
    <AnimatePresence>
      {isOpen2 && (
        <Overlay
          initial={"initial"}
          animate={"isOpen"}
          exit={"exit"}
          variants={modalVariant}
        >
          <ModalContainer1 variants={containerVariant}>
          <CloseButton
              onClick={handleClose}
              viewBox="0 0 20.39 20.39"
            >
              <AiOutlineClose style={{fontsize: '25px'}}/>
            </CloseButton>
            {children}
          </ModalContainer1>
        </Overlay>
      )}
    </AnimatePresence>
  );
};

export default Modalvtkcontrol;