export const PageNotFound ={
    lightBg: true,
    primary: false,
    imgStart: 'start',
    lightTopLine: false, 
 
    description: 'la página que estas buscando no existe', 
    headline: '404', 
    buttonLabel: 'Ir al inicio', 
  
    topLine: 'Página no encontrada',
    start: 'true',
    img: require('../../images/chicapc.svg').default,
    alt: 'imagen persona en computador',
    width: 'auto',
    height: 'auto'
  }
  export const Metadata={
    pagetitle: 'Bartik | 404',
    pagedescription: 'Página no encontrada, la página que estas buscando no existe'
  }