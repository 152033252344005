export const AboutObjOne ={
    lightBg: true,
    imgStart: 'start',
    lightTopLine: true, 
    buttonLabel: '', 
    description: 'Después de dedicarnos por más de 30 años a trabajar en empresas de servicios de vigilancia y saber sus falencias y sus capacidades, decidimos aportar nuestro granito de arena y sumergirnos en la idea de crear y desarrollar software que facilitaran todo tipo de comunicación, tanto áreas - trabajadores, como cliente - empresa, sabiendo que nuestro compromiso era hacer que la tecnología fuera parte de nuestro diario vivir, pero sin olvidarnos que hay personas que no necesariamente son usuarios y amantes de la tecnología y es a ellos a quienes vamos a hacerles la vida fácil, personas que pertenecen a su compañía y que les cuesta tener acceso fácil a todos los cambios tecnológicos, pero que sin ellos no tendría la compañía que tiene hoy en día. Adicionalmente sabemos que los equipos tecnológicos cambian cada rato y que instalar lo mejor para prestar sus servicios es de suma importancia, por lo tanto queremos que todo sea integrado a las nuevas tecnologías en su compañia.', 
    headline: 'NUESTRA COMPAÑIA', 
    start: '',
    img2: require('../../images/bartikgreen.svg').default,
    alt: 'imagen Bartik Verde',
    width: 'auto',
    height: 'auto'
  }
  
  export const AboutObjTwo ={
    lightBg: false,
    imgStart: 'start',
    lightTopLine: true, 
    description: 'Llegar a tener más de 100 clientes en Colombia y el mundo .........', 
    headline: 'NUESTRA VISIÓN', 
    topLine: 'BARTIK',
    start: 'true',
    img2: require('../../images/vision.svg').default,
    img: require('../../images/line2.svg').default,
    alt: 'imagen nuestra visión',
    width: 'auto',
    height: 'auto'
  }

  export const AboutObjThree  ={
    lightBg: true,
    imgStart: 'start',
    lightTopLine: false, 
    buttonLabel: 'CONTACTANOS', 
    description: 'Implementamos soluciones 360º que se adecuan a las necesidades de cada uno de estos sectores.', 
    description1: 'Integramos las marcas apropiadas e instalamos las soluciones con un equipo certificado.', 
    description2: 'Equipo de técnicos capacitados para brindarte soporte oportuno y de confianza.', 
    headline: 'NUESTRA MISIÓN', 
    headline1: '', 
    headline2: '', 
    topLine: 'INOVACIÓN',
    topLine1: 'SOLUCIÓN',
    topLine2: 'DESARROLLO',
    start: 'true',
    img: require('../../images/mision.svg').default,
    alt: 'imagen nuestra visión',
    width: 'auto',
    height: 'auto'
  }
 export const Metadata={
   pagetitle: 'Bartik | Nosotros',
   pagedescription: 'descripcion de la pagina sbore nsotros'
 }
  
  
  
  