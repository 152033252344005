import React, { useState, useEffect } from 'react'
import { IconContext } from 'react-icons/lib';
import { 
  Nav, 
  NavbarContainer, 
  NavLogo, 
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavItemBtn,
  NavBtnLink
} from './Navbar.elements';
import { FaBars, FaTimes } from 'react-icons/fa'
import { Button  } from '../../globalStyles';
export const Navbar = () => {
  const [click, setClick] = useState(false)
  const [button, setButton] = useState(true)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const showButton = () => {
     if(window.innerWidth <= 960) {
       setButton(false)
     } else {
       setButton(true)
     }
  }
  
  useEffect(() => {
    showButton()
  }, [])
window.addEventListener('resize', showButton)
  return (
    <>
      
    <IconContext.Provider value={{color: '#fff'}} >
      <Nav>
        <NavbarContainer>
            <NavLogo to="/" onClick={closeMobileMenu}>
               <img src={require('../../images/logoClaro.svg').default} alt="Logo" width="148px" height="auto"/> 
            </NavLogo>
            
            <MobileIcon
            onClick={handleClick}
            >
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to="/">
                  HOME
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/nosotros">
                  SOBRE NOSOTROS
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/servicios">
                  SERVICIOS
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/portafolio">
                  PORTAFOLIO
                </NavLinks>
              </NavItem>
              <NavItemBtn>
                {button ? (
                  <NavBtnLink to ="/contactanos">
                    <Button primary="true">
                      CONTACTANOS
                    </Button>
                  </NavBtnLink>
                ) : (
                  <NavBtnLink to ="/contactanos">
                    <Button  
                    fontbig="true"
                    primary="true">
                      CONTACTANOS
                    </Button>
                  </NavBtnLink>
                )}
              </NavItemBtn>
              
            </NavMenu>
           
        </NavbarContainer>
       
      </Nav>
      
      </IconContext.Provider>
      
    </>
  )
}

export default Navbar;