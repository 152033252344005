export const lightTheme = {
  footer: 'linear-gradient(90deg, rgba(113,154,63,1) 0%, rgba(196,217,96,1) 40%, rgba(196,217,96,1) 63%, rgba(113,154,63,1) 100%)',
  toggle: '#182539',
  toggleBorder: '#262626',
  Cardcolor: 'linear-gradient(90deg, rgba(113,154,63,1) 0%, rgba(196,217,96,1) 40%, rgba(196,217,96,1) 63%, rgba(113,154,63,1) 100%)',
  navcolor: 'linear-gradient(90deg, rgba(113,154,63,1) 0%, rgba(196,217,96,1) 80%, rgba(113,154,63,1) 100%)',
  secwhite: '#fff',
  secgreen: 'linear-gradient(90deg, rgba(113,154,63,1) 0%, rgba(196,217,96,1) 29%, rgba(196,217,96,1) 63%, rgba(113,154,63,1) 100%)',
  bgmenu: '#c4d960',
  subtitle: '#35560c',
  headigh4: '#1c2237',
  headigh4white: '#F7FAFC',
  input: '#1c2237',
  cardservices: 'linear-gradient(1.67deg, #70983F 3.7%, #C4D960 139.22%)',
  cardportfolio: '#F7FAFC',
  Slider: 'translateX(0px)',
  Slidercolor: '#c4d960'
}

  
  export const darkTheme = {
    body: '#4B586F',
    toggleBorder: '#c4d960', 
    background: '#182539',
    Cardcolor:  '#182539',
    footer: '#182539',
    toggle: '#4B586F',
    navcolor: '#202C3F',
    secwhite: '#202c3f',
    secgreen: 'linear-gradient(90deg, rgba(32,44,63,1) 0%, rgba(75,88,111,1) 48%, rgba(24,37,57,1) 100%)',
    bgmenu: '#202C3F',
    subtitle: '#E2E8F0',
    headigh4: '#F7FAFC',
    headigh4white: '#F7FAFC',
    input: '#F7FAFC',
    cardservices: '#4B586F',
    cardportfolio: '#182539',
    Slider: 'translateX(23.4px)',
    Slidercolor: '#F7FAFC'
  }