import React from 'react';
import { FaInstagram, FaLinkedinIn} from 'react-icons/fa';
import { Button } from '../../globalStyles';
import { FooterContainer,
  FooterSubscription,
  FooterSubHeading,
  FooterSubText,
  Form,
  FormInput,
  FooterLinkContainer,
  FooterLinksWraper,
  FooterLinkTitle,
  FooterLinksItems,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialMediaLogo,
  SocialIcon,
  SocialIconsLink,
  SocialIcons,
  WebSiteRight
 } from './Footer.elements';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterSubscription>
        <FooterSubHeading>
          Suscribete a nuestro portal 
        </FooterSubHeading>
        <FooterSubText>
          Escribenos
        </FooterSubText>
        <Form action="https://formsubmit.co/202961dba09ae1a95fba08e351460c0b" method="POST">
          <FormInput name="name" type="text" placeholder="Nombre Completo" required="required" />
          <FormInput name="phone" type="text" placeholder="Telefono" required="required"/>
          <FormInput name="email" type="email" placeholder="Correo" required="required" />
            <Button 
            fontbig="true"
            primary="true">
              Suscribete
            </Button>
        </Form>
      </FooterSubscription>
      <FooterLinkContainer>
        <FooterLinksWraper>
          <FooterLinksItems>
            <FooterLinkTitle>
              BARTIK
            </FooterLinkTitle>
            <FooterLink to="/">Home</FooterLink>
            <FooterLink to="/nosotros">Sobre nosotros</FooterLink>
            <FooterLink to="/servicios">Servicios</FooterLink>
            <FooterLink to="/portafolio">Portafolio</FooterLink>
          </FooterLinksItems>
          <FooterLinksItems>
            <FooterLinkTitle>
             NOSOTROS
            </FooterLinkTitle>
            <FooterLink to="/nosotros">Compañia</FooterLink>
            <FooterLink to="/nosotros">Visión</FooterLink>
            <FooterLink to="/nosotros">Misión</FooterLink>
          </FooterLinksItems>
          </FooterLinksWraper>
          <FooterLinksWraper>
          <FooterLinksItems>
            <FooterLinkTitle>
              SERVICIOS
            </FooterLinkTitle>
            <FooterLink to="/servicios">Instalación</FooterLink>
            <FooterLink to="/servicios">Mantenimiento</FooterLink>
            <FooterLink to="/servicios">Reparación</FooterLink>
            <FooterLink to="/servicios">Venta</FooterLink>
          </FooterLinksItems>
          <FooterLinksItems>
            <FooterLinkTitle>
              PORTAFOLIO
            </FooterLinkTitle>
            <FooterLink to="/portafolio">Visitik</FooterLink>
            <FooterLink to="/portafolio">Visitik App</FooterLink>
          </FooterLinksItems>
        </FooterLinksWraper>
      </FooterLinkContainer>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialMediaLogo to="/">
            <SocialIcon />
            BARTIK
          </SocialMediaLogo>
          <WebSiteRight>Derechos reservados 2022</WebSiteRight>
          <SocialIcons>
            <SocialIconsLink href="/" target="_blank" area-label="instagram">
              <FaInstagram />
              </SocialIconsLink>
              <SocialIconsLink href="/portafolio" area-label="Linkedin">
            <FaLinkedinIn />
              </SocialIconsLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  )
}


export default Footer;
