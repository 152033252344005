import { Link } from 'react-router-dom'
import { Button, Container } from '../../globalStyles'
import { 
  InfoSec, 
  InfoRow, 
  InfoColumn, 
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
  Logo
 } from './Page404.Element'

const InfoSection = ({
  lightBg, 
  imgStart, 
  lightTopLine,
  buttonLabel, 
  description, 
  headline, 
  topLine,
  start,
  img,
  alt,
  width ,
  height
}) => {

  const fadeLeft = {
    hidden: {opacity : 0, x:-100},
    visible: {opacity: 1, x:0}
  }

  return (
    <>
      <InfoSec lightBg={lightBg}>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper>
                  <Logo><img src={require('../../images/LogoOscuro.svg').default} alt="Logo" /></Logo>
                  <Heading 
                  variants={fadeLeft}
                  initial='hidden'
                  animate='visible'
                  transition={{duration: 1}}
                  >{headline}
                  </Heading>
                   <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                  <Subtitle >{description}</Subtitle>
                  <Link to='/'>
                    <Button whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.9 }} 
                            big="true"
                            fontbig="true"
                            primary="true">
                      {buttonLabel}
                    </Button>
                  </Link>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
            
              <ImgWrapper start={start}>
                <Img src={img} alt={alt} width={width} height={height} />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  )
}

export default InfoSection
