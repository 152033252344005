export const PortafolioObjOne ={
    lightBg: false,
    primary: false,
    imgStart: '',
    lightTopLine: true, 
  
    buttonLabel: 'CONTACTANOS', 
    headline: 'Visitik', 
   
    descriptionapp: 'Praesent lobortis massa eu odio blandit tincidunt.Etiam tincidunt arcu vitae orci condimentum consequat.Nulla et dictum sem, in auctor lectus. Sed...Praesent lobortis massa eu odio blandit tincidunt.Etiam tincidunt arcu vitae orci condimentum consequat.Nulla et dictum sem, in auctor lectus. Sed Praesent lobortis massa eu odio blandit tincidunt.Etiam tincidunt arcu vitae orci condimentum consequat.Nulla et dictum sem, in auctor lectus. Sed...',
    start: '',
    img: require('../../images/visitikregistro.svg').default,
    alt: 'visitik-registro de visitantes',
    img1: require('../../images/visitikapp.svg').default,
    alt1: 'visitik app mobile',
    width: 'auto',
    height: 'auto'
  }
  export const Metadata={
    pagetitle: 'Bartik | Portafolio',
    pagedescription: 'Descripcion pagina portafolio'
  }
  
  