import {  AboutObjOne, AboutObjThree, AboutObjTwo } from './Data'
import { Footer, InfoSection3, Navbar} from '../../components'
import MetaDecorator from '../../components/Utils/Metadata'
import InfoSectionBgWhite from '../../components/InfoSectionAbout/InfoSectionBgWhite'
import InfoSectionBggreen from '../../components/Infosection2/InfoSectionBggreen'

const about = require ("./Data")
const About = () => {
  
  
  
  return (
    <>
    < Navbar />
    <MetaDecorator title={about.Metadata.pagetitle} description={about.Metadata.pagedescription}/>
    <InfoSectionBgWhite id="compañia" {...AboutObjOne} />
    <InfoSectionBggreen id="vision" {...AboutObjTwo} />
    <InfoSection3  {...AboutObjThree} className="mision"/>
    < Footer/>
    </>
  )
}

export default About