import React from 'react'
import { PortafolioObjOne } from './Data'
import { InfoSectionPortfolio, Navbar, Footer} from '../../components'
import MetaDecorator from '../../components/Utils/Metadata'

const portafolio = require ("./Data")
const Portafolio = () => {
  return (
    <>
    <Navbar/>
    <MetaDecorator title={portafolio.Metadata.pagetitle} description={portafolio.Metadata.pagedescription}/>
    <InfoSectionPortfolio {...PortafolioObjOne} />
    <Footer/>
    </>
  )
}

export default Portafolio