export const ServiceObjOne  ={
  lightBg: true,
  primary: false,
  imgStart: 'start',
  lightTopLine: false, 
  description: 'Praesent lobortis massa eu odio blandit tincidunt.Etiam tincidunt arcu vitae orci condimentum consequat.Nulla et dictum sem, in auctor lectus. Sed...', 
  description1: 'Integramos las marcas apropiadas e instalamos las soluciones con un equipo certificado.', 
  description2: 'Equipo de técnicos capacitados para brindarte soporte oportuno y de confianza.',
  topLine: ' Instalación de equipos',
  topLine1: ' Mantenimiento',
  topLine2: ' Reparación de equipos',
  topLine3: ' Venta de equipos',
  topLine4: ' Integración de sistemas',
  topLine5: ' Reparación de equipos',
  start: 'true',
  
  img: require('../../images/servicios1.svg').default,
  alt: 'Nuestos servicios',
  width: 'auto',
  height: 'auto'
}
export const Metadata={
  pagetitle: 'Bartik | Servicios',
  pagedescription: 'Descripcion pagina Servicios'
}


