import styled from "styled-components";
import { motion } from "framer-motion";


export const InfoSecwhite = styled.div`
color: #fff;
padding: 20px 0;
background: ${({ theme }) => theme.secwhite};
`;

export const InfoSecgreen = styled.div`
color: #fff;
padding: 90px 0;
background: ${({ theme }) => theme.secgreen};

`;

export const InfoRow = styled.div`
display: flex;
margin: 0 -15px -15px -15px;
flex-wrap: wrap;
align-items: center;
flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};
`;

export const InfoColumn = styled.div`
margin-bottom: 15px;
padding-right: 15px;
padding-left: 15px;
flex: 1;
max-width: 50%;
flex-basis: 50%;

@media screen and (max-width: 768px) {
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}
`;

export const TextWrapper = styled.div`
max-width: 540px;
padding-top: 0;
padding-bottom: 60px;

@media screen and (max-width: 768px) {
  padding-bottom: 2px;
}
`;

export const TopLine = styled.div`
color: ${({ theme }) => theme.subtitle};
font-size: 18px;
line-height: 16px;
letter-spacing: 1.4px;
margin-bottom: 16px;
`;

export const Heading = styled(motion.h1)`
margin-bottom: 24px;
font-size: 48px;
line-height: 1.1;
color: '#F7FAFC';
text-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;

export const Subtitle = styled.p`
max-width: 440px;
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
color: ${({ theme }) => theme.subtitle};
`;

export const ImgWrapper = styled.div`
max-width: 555px;
display: flex;
justify-content: ${({start}) => (start ? 'flex-start' : 'flex-end')};
`;

export const Img = styled.img`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
display: inline-block;
max-height: 500px;
width: auto;
heigth: auto;

`;
export const HeadingH4 = styled(motion.h4)`
margin-bottom: 24px;
font-size: 48px;
line-height: 1.1;
color: ${({ theme }) => theme.headigh4};
text-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;

@media screen and (max-width: 768px) {
  font-weight: normal;
}
`;

export const HeadingH4white = styled(motion.h4)`
margin-bottom: 24px;
font-size: 48px;
line-height: 1.1;
color: ${({ theme }) => theme.headigh4white};
text-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;