import React from 'react'
import { func } from 'prop-types';
import styled from "styled-components"



const Slider = styled.span`

cursor: pointer;
z-index :9999;
left: 0;
right: 0;
bottom: 0;
background-color: ${({ theme }) => theme.Slidercolor};
border-radius: 15px;
transition: 0.4s;
position: absolute;
left: 2px;
bottom: 2px;
width: 20px;
height: 20px;
border-radius: 100%;
transform: ${({ theme }) => theme.Slider};
transition: 0.4s; 
`;

 const Button = styled.button`
  
 border:none;
 background: ${({ theme }) => theme.body};
 border-radius: 30px;
 cursor: pointer;
 font-size: 0.5rem;
 overflow: hidden;
 position: fixed;
 width: 48px;
 height: 24px;
 outline: none;
 z-index :9999;
 left: 89%;
 margin-top 90px;
 display: inline-block;
 align-items: center;
 transition: 0.4s;
   
   @media screen and (max-width: 768px) {
     left: 80%;
       }
 `;

const Toggle = ({ toggleTheme }) => {
  
    return (
      <Button onClick={toggleTheme}>
      <Slider  />
      </Button>
    );
};


Toggle.propTypes = {
    toggleTheme: func.isRequired
}

export default Toggle;