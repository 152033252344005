import styled from "styled-components";
import { motion } from "framer-motion";

export const InfoSec = styled.div`
color: #fff;
padding: 20px 0;
padding-bottom: 100px;
background: ${({ theme }) => theme.secwhite};
`;

export const InfoRow = styled.div`
display: grid;
margin: 0 -15px -15px -15px;
flex-wrap: wrap;
align-items: center;
flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};

`;

export const InfoColumn = styled(motion.div)`
margin-bottom: 15px;
padding-right: 15px;
padding-left: 15px;
display: grid;
justify-content: center;


@media screen and (max-width: 768px) {
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}
`;
export const InfoGrid = styled.div`
margin-bottom: 15px;
padding-right: 15px;
padding-left: 15px;
display: grid;

justify-content: center;
grid-template-columns: 1fr 1fr 1fr;
gap : 20px;



@media screen and (max-width: 768px) {
  max-width: 100%;
  flex-basis: 100%;
  display: inline-block;
  justify-content: center;
}
`;

export const TextWrapper = styled(motion.div)`
max-width: 540px;
padding-top: 0;
background: ${({ theme }) => theme.cardservices};
border-radius: 7px;
box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
transition: all 0.2s;

&:hover{
  transform: scale(1.5,1.5);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  background: '#D0BDBD';
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  padding-bottom: 2px;
  margin-bottom: 25px;
}
`;

export const TopLine = styled.div`
color: ${({ theme }) => theme.subtitle};
font-size: 18px;
line-height: 16px;
letter-spacing: 1.4px;
margin: 20px;

@media screen and (max-width: 768px) {
  padding-top 25px;
 
}
`;

export const Heading = styled(motion.h1)`
margin-bottom: 24px;
font-size: 48px;
line-height: 1.1;
color: '#f7f8fa' ;
text-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;

export const Subtitle = styled.p`
max-width: 440px;
margin-bottom: 35px;
margin: 20px;
font-size: 18px;
line-height: 24px;
color: ${({ theme }) => theme.subtitle};
`;

export const ImgWrapper = styled.div`
max-width: 555px;
display: flex;
justify-content: ${({start}) => (start ? 'flex-start' : 'flex-end')};
`;

export const Img = styled.img`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
display: inline-block;
max-height: 500px;

`;