export const HomeObjOne ={
  primary: true,
  imgStart: '',
  buttonLabel: 'CONTACTANOS', 
  description: 'Implementamos soluciones 360º que se adecuan a las necesidades de cada uno de estos sectores.', 
  headline: 'Transformamos la tecnología', 
  start: '',
  img: require('../../images/bartik.svg').default,
  alt: 'imagen Bartik',
  width: 'auto',
  height: 'auto'
}

export const HomeObjTwo ={
  imgStart: 'start',
  headline: 'Presentamos la nueva forma de implementar soluciones 360º que se adecuan a las necesidades de cada uno de los sectores.', 
  start: 'true',
  img2: require('../../images/app.svg').default,
  img: require('../../images/line.svg').default,
  alt: 'imagen aplicaciones bartik',
  atl2: 'imagen linea',
  width: 'auto',
  height: 'auto'
}

export const HomeObjThree ={
  imgStart: '',
  buttonLabel: 'CONTACTANOS', 
  description: 'BARTIK nace el 6 de septiembre de 2021 con la idea de tranformar y facilitar a todas las compañias de servicios la comunicación entre trabajadores mismos y sus clientes..', 
  headline: 'Bartik-ing', 
  start: '',
  img2: require('../../images/tecno.svg').default,
  alt: 'imagen tecnologia',
  width: 'auto',
  height: 'auto'
}

export const HomeObjFour ={
  imgStart: 'start',
  description: 'Empresa dedicada a la implementación y desarrollo de tecnologías que aseguren el bienestar de las personas, empresas, industrias o comercios a través de la implementación de medios tecnológicos y desarrollo de software o aplicaciones a medida.', 
  headline: 'Soluciones tecnológicas para su industria', 
  topLine: 'Paola Nino Top Line',
  start: 'true',
  img2: require('../../images/computador.svg').default,
  alt: 'imagen persona en computador',
  width: 'auto',
  height: 'auto'
}
export const Metadata={
  pagetitle: 'Bartik | Home',
  pagedescription: 'Empresa dedicada a la implementación y desarrollo de tecnologías que aseguren el bienestar de las personas, empresas, industrias o comercios a través de la implementación de medios tecnológicos y desarrollo de software o aplicaciones a medida.'
}