import React, { useState } from "react";
import { Container } from '../../globalStyles'
import { 
  InfoSec, 
  InfoRow, 
  InfoColumn, 
  TextWrapper,
  Heading,
  HeadingH4,
  Img
  
 } from './InfoSectionPortfolio.elements'
import Modalvtk from '../Modals/Modalvtk'
import OpenModalButton from '../Modals/OpenModalButton'
import Modalvtkcontrol from "../Modals/Modalvtkcontrol";
import {ModalContentvtk} from "../Modals/Modalvtk";
import { ModalContentvtkcontrol } from "../Modals/Modalvtkcontrol";
import { ColumInfo, WraperVideo, WraperImg1, WraperImg2, HeadingModals, SubtitleModals, ImgModal} from "../Modals/Modals.Elements";
  

const InfoSectionPortfolio = ({
  
  lightBg, 
  imgStart,  
  headline, 
  lighttext, 
  img,
  img1,
  alt,
  alt1,
  descriptionapp,
  lighttextDesc,
  width ,
  height
  
}) => {

  const fadeLeft = {
    hidden: {opacity : 0, x:-100},
    visible: {opacity: 1, x:0}
  }
  



  const fadeRight = {
    hidden: {opacity : 0, x:200},
    visible: {opacity: 1, x:0}
  }

  const [isOpen,  toggle] = useState(false);
  function handlOpenModalvtk(open) {
    
    toggle(open);
  }
  const [isOpen2,  toggle2] = useState(false);
  function handlOpenModalvtkcontrol(open) {
    
    toggle2(open);
  }

  return (
    <>
      <InfoSec lightBg={lightBg}>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper   
              variants={fadeLeft}
              initial='hidden'
              animate='visible'
              transition={{duration: 1}}>  
                  <Heading
                  lighttext={lighttext}>{headline}
                  <OpenModalButton handlClick={() => handlOpenModalvtk(true)}>
                     VER
                  </OpenModalButton>
                  <Modalvtk isOpen={isOpen} handleClose={() => handlOpenModalvtk(false)}>
                  <ModalContentvtk>     
                  <ColumInfo>
                              <HeadingModals
                                 lighttext={lighttext}>Visitik-App
                              </HeadingModals>
                              <SubtitleModals
                                lighttextDesc={lighttextDesc}>{descriptionapp}
                              </SubtitleModals>
                              
                        </ColumInfo>
                        <WraperVideo>
                              <HeadingModals
                                 lighttext={lighttext}>video
                              </HeadingModals>
                        </WraperVideo>
                        <WraperImg1>
                        <ImgModal src={img} alt={alt} width={width} height={height} whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.9 }}/>
                        </WraperImg1>
                        <WraperImg2>
                        <ImgModal src={img} alt={alt} width={width} height={height} whileHover={{ scale: 1.03 }}  whileTap={{ scale: 0.9 }}/>
                        </WraperImg2>
                  </ModalContentvtk>
                  </Modalvtk>
                  </Heading>
                  <Img src={img} alt={alt} width={width} height={height}whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.9 }}/>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
            <TextWrapper
               variants={fadeRight}
               initial='hidden'
               animate='visible'
               transition={{duration: 1}}>
                  <HeadingH4
                  lighttext={lighttext}>Visitik App 
                  <OpenModalButton handlClick={() => handlOpenModalvtkcontrol(true)}>
                  VER 
                  </OpenModalButton>
               <Modalvtkcontrol isOpen2={isOpen2} handleClose={() => handlOpenModalvtkcontrol(false)}>
               <ModalContentvtkcontrol>     
                       <ColumInfo>
                              <HeadingModals
                                 lighttext={lighttext}>Visitik-App
                              </HeadingModals>
                              <SubtitleModals
                                lighttextDesc={lighttextDesc}>{descriptionapp}
                              </SubtitleModals>
                              
                        </ColumInfo>
                        <WraperVideo>
                              <HeadingModals
                                 lighttext={lighttext}>video
                              </HeadingModals>
                        </WraperVideo>
                        <WraperImg1>
                        <ImgModal src={img} alt={alt} width={width} height={height} whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.9 }}/>
                        </WraperImg1>
                        <WraperImg2>
                        <ImgModal src={img} alt={alt} width={width} height={height} whileHover={{ scale: 1.03 }}  whileTap={{ scale: 0.9 }}/>
                        </WraperImg2>
               </ModalContentvtkcontrol>
               </Modalvtkcontrol>
               </HeadingH4>
                  <Img src={img1} alt={alt1} width={width} height={height} whileHover={{ scale: 1.03 }}  whileTap={{ scale: 0.9 }}/>
              </TextWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  )
}

export default InfoSectionPortfolio