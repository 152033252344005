import React from 'react'
import MetaDecorator from '../../components/Utils/Metadata'
import { PageNotFound } from './Data'
import { Page404notfound } from '../../components'



const page404  = require ("./Data")
const Page404 = () => {

  return (
    
    <>
    <MetaDecorator title={page404.Metadata.pagetitle} description={page404.Metadata.pagedescription}/>
    <Page404notfound {...PageNotFound} />
   
    </>
  )
}

export default Page404