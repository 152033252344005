import React from 'react'
import { BsTools } from "react-icons/bs"; 
import { GiToolbox } from "react-icons/gi";
import { AiFillApi, AiFillSetting, AiOutlineGlobal, AiFillTags } from "react-icons/ai";




import { Container } from '../../globalStyles'
import { 
  InfoSec, 
  InfoRow, 
  InfoColumn, 
  InfoGrid,
  TextWrapper,
  TopLine,
  Subtitle,
  ImgWrapper,
  Img
 } from './InfoSectionService.elements'

const InfoSectionService = ({
  lightBg, 
  imgStart, 
  lightTopLine, 
  description, 
  description1,
  topLine,
  topLine1,
  topLine2,
  topLine3,
  topLine4,
  topLine5,
  start,
  img,
  alt,
  width ,
  height
}) => {

  const fadeLeft = {
    hidden: {opacity : 0, x:-100},
    visible: {opacity: 1, x:0},
    
  }
  const fadeUp = {
    hidden: {opacity : 0, y:-100},
    visible: {opacity: 1, y:0},
  }


  return (
    <>
      <InfoSec lightBg={lightBg}>
        <Container>
          <InfoRow imgStart={imgStart}>
          <InfoColumn variants={fadeLeft}
                  initial='hidden'
                  animate='visible'
                  transition={{duration: 1}}
                  >
              <ImgWrapper start={start}>
                <Img src={img} alt={alt} width={width} height={height} />
            </ImgWrapper>
            </InfoColumn>
            <InfoGrid>
              <TextWrapper 
                  whileHover={{ scale: 1.06, transition:{delay: 0}, duration: 0.1}}
                  whileTap={{scale: 1.06, duration: 0.1, transition:{delay: 0, duration:0.1}}}
                  variants={fadeLeft}
                  initial='hidden'
                  animate='visible'
                  transition={{delay: 2}}>          
                  <TopLine lightTopLine={lightTopLine} style={{color: '#f7f8fa', fontSize: '1.6em'}}>< GiToolbox style={{color: '#f7f8fa', fontSize: '30px'}} />{topLine}</TopLine>
                  <Subtitle >{description}</Subtitle>
              </TextWrapper>  
              <TextWrapper variants={fadeUp}
                  initial='hidden'
                  animate='visible'
                  transition={{delay: 2}}
                  whileHover={{ scale: 1.06}}
                  whileTap={{scale: 1.06, duration: 0.1}}>  
              <TopLine lightTopLine={lightTopLine} style={{color: '#f7f8fa', fontSize: '1.6em'}}>< AiFillSetting style={{color: '#f7f8fa'}} />{topLine1}</TopLine>
                  <Subtitle >{description}</Subtitle>
              </TextWrapper>
              <TextWrapper variants={fadeLeft}
                  initial='hidden'
                  animate='visible'
                  transition={{delay: 2}}
                  whileHover={{ scale: 1.06}}
                  whileTap={{scale: 1.06, duration: 0.1}}> 
              <TopLine lightTopLine={lightTopLine} style={{color: '#f7f8fa', fontSize: '1.6em'}}><  BsTools style={{color: '#f7f8fa', fontSize: '30px'}} />{topLine2}</TopLine>
                  <Subtitle >{description}</Subtitle>
              </TextWrapper>    
              <TextWrapper variants={fadeLeft}
                  initial='hidden'
                  animate='visible'
                  transition={{delay: 2}}
                  whileHover={{ scale: 1.06}}
                  whileTap={{scale: 1.06, duration: 0.1}}>   
              <TopLine lightTopLine={lightTopLine} style={{color: '#f7f8fa', fontSize: '1.6em'}}>< AiFillTags style={{color: '#f7f8fa', fontSize: '30px'}} />{topLine3}</TopLine>
                  <Subtitle >{description}</Subtitle>
              </TextWrapper> 
              <TextWrapper variants={fadeLeft}
                  initial='hidden'
                  animate='visible'
                  transition={{delay: 2}}
                  whileHover={{ scale: 1.06}}
                  whileTap={{scale: 1.06, duration: 0.1}}> 
              <TopLine lightTopLine={lightTopLine} style={{color: '#f7f8fa', fontSize: '1.6em'}}>< AiFillApi style={{color: '#f7f8fa', fontSize: '30px'}} />{topLine4}</TopLine>
                  <Subtitle >{description}</Subtitle>
               </TextWrapper>   
               <TextWrapper variants={fadeLeft}
                  initial='hidden'
                  animate='visible'
                  transition={{delay: 2}}
                  whileHover={{ scale: 1.06}}
                  whileTap={{scale: 1.06, duration: 0.1}}>    
               <TopLine lightTopLine={lightTopLine} style={{color: '#f7f8fa', fontSize: '1.6em'}}>< AiOutlineGlobal style={{color: '#f7f8fa', fontSize: '30px'}} />{topLine5}</TopLine>
                  <Subtitle >{description1}</Subtitle>
              </TextWrapper> 
              
            </InfoGrid>
          </InfoRow>
        </Container>
      </InfoSec>
    </>
  )
}

export default InfoSectionService