import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Container } from '../../globalStyles'
import { 
   
  InfoRow, 
  InfoColumn, 
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
  InfoSecgreen
 } from './InfoSection.elements'

const InfoSection = ({
  imgStart,  
  buttonLabel, 
  description, 
  headline, 
  topLine,
  start,
  img,
  alt,
  width ,
  height
}) => {

  const fadeLeft = {
    hidden: {opacity : 0, x:-100},
    visible: {opacity: 1, x:0}
  }

  return (
    <>
      <InfoSecgreen>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper>
                  <TopLine >{topLine}</TopLine>
                  <Heading 
                  variants={fadeLeft}
                  initial='hidden'
                  animate='visible'
                  transition={{duration: 1}}>
                  {headline}
                  </Heading>
                  <Subtitle>{description}</Subtitle>  
                  <Link to='/contactanos'>
                    <Button whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }} 
                            big="true" 
                            fontbig="true"
                            primary="true">
                      {buttonLabel}
                    </Button>
                  </Link>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start={start}>
                <Img src={img} alt={alt} width={width} height={height}/>
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSecgreen>
    </>
  )
}

export default InfoSection
