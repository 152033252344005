import styled, {createGlobalStyle} from "styled-components";
import { motion } from "framer-motion";

const GlobalStyle = createGlobalStyle`
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  }
  body{
    background: ${({ theme }) => theme.body};
    transition: all 1 s linear;
  }
  `


export const Container = styled.div`
z-index: 1;
width: 100%;
max-width: 1300px;
margin-right: auto;
margin-left: auto;
padding-left: 50px;
padding-right: 50px;

@media screen and (max-width: 991px) {
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 0;
  margin-left: 0;
}
`;

export const Button = styled(motion.button)`
border-radius: 4px;
background: ${({primary}) => (primary ? '#719a3f' : '#c3d960') };
white-space: nowrap;
padding: ${({big}) => (big ? '12px 64px' : '10px 20px') };
color: #fff;
font-size: ${({fontbig}) => (fontbig ? '20px' : '16px') };
outline: none;
border: none;
cursor: pointer;
text-transform: uppercase;

&:hover{
  transition: all 0.3s ease-out;
  background: #fff;
  background: ${({primary}) => (primary ? '#35560c' : '#719a3f') };
}

@media screen and (max-width: 960px){
  width: 100%;
}
`;

export default GlobalStyle