import React from 'react';
import { ServiceObjOne  } from './Data'
import { Footer, InfoSectionService, Navbar } from '../../components'
import MetaDecorator from '../../components/Utils/Metadata';

const servicios = require ("./Data")
const Servicios = () => {
  return (
    <>
    <Navbar/>
    <MetaDecorator title={servicios.Metadata.pagetitle} description={servicios.Metadata.pagedescription}/>
    <InfoSectionService {...ServiceObjOne } />
    <Footer/>
    </>
  )
}

export default Servicios