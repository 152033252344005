import React from 'react'
import { Container } from '../../globalStyles'
import { 
  
  InfoRow, 
  InfoColumn, 
  TextWrapper,
  TopLine,
  HeadingH4white,
  Subtitle,
  ImgWrapper,
  Img,
  InfoSecgreen
 } from '../InfoSection/InfoSection.elements'

const InfoSectionBggreen = ({
  
  imgStart, 
  lightTopLine, 
  description, 
  headline, 
  topLine,
  start,
  img,
  img2,
  alt,
  alt2,
  width ,
  height
}) => {

  const fadeLeft = {
    hidden: {opacity : 0, x:-100},
    visible: {opacity: 1, x:0}
  }

  return (
    <>
      <InfoSecgreen>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper>
                  <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                  <HeadingH4white
                  variants={fadeLeft}
                  initial='hidden'
                  animate='visible'
                  transition={{duration: 1}}>{headline}</HeadingH4white>
                  <Subtitle >{description}</Subtitle>
                  <Img src={img} alt={alt2} width={width} height={height}/>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start={start}>
                <Img src={img2} alt={alt} width={width} height={height}/>
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSecgreen>
    </>
  )
}

export default InfoSectionBggreen
