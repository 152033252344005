import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const OpenModalButton = styled(motion.button)`
border-radius: 4px;
background: ${({primary}) => (primary ? '#719a3f' : '#c3d960') };
white-space: nowrap;
padding: ${({big}) => (big ? '12px 64px' : '10px 20px') };
color: #fff;
font-size: ${({fontbig}) => (fontbig ? '20px' : '16px') };
outline: none;
border: none;
cursor: pointer;
text-transform: uppercase;

&:hover{
  transition: all 0.3s ease-out;
  
  background: ${({primary}) => (primary ? '#35560c' : '#719a3f') };
}

@media screen and (max-width: 960px){
  width: 100%;
}
`;
const animatedOpenButton = ({ children, handlClick }) => {
  return (
    <OpenModalButton
      onClick={handlClick}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      {children}
    </OpenModalButton>
  );
};

export default animatedOpenButton;