import React from 'react'
import MetaDecorator from '../../components/Utils/Metadata'
import { HomeObjFour, HomeObjOne, HomeObjThree, HomeObjTwo } from './Data'
import { InfoSection, InfoSection2,  Navbar, Footer} from '../../components'
import InfoSectionBggreen from '../../components/Infosection2/InfoSectionBggreen'



const home  = require ("./Data")
const Home = () => {

  return (
    
    <>
    <MetaDecorator title={home.Metadata.pagetitle} description={home.Metadata.pagedescription}/>
    < Navbar />
    <InfoSection {...HomeObjOne} />
    <InfoSection2 {...HomeObjTwo} />
    <InfoSectionBggreen {...HomeObjThree} />
    <InfoSection2 {...HomeObjFour} />
    <Footer />
    </>
  )
}

export default Home