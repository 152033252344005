import React from 'react'
import { IoFlashOutline, IoInfiniteOutline,IoRocketOutline } from "react-icons/io5";



import { Container } from '../../globalStyles'
import { 

  InfoRow, 
  InfoColumn, 
  TextWrapper,
  TopLine,
  HeadingH4,
  Subtitle,
  ImgWrapper,
  Img,
  InfoSecwhite
 } from '../InfoSection/InfoSection.elements'

const InfoSection3 = ({

  imgStart, 
  lighttextDesc, 
  description, 
  description1,
  description2,
  headline, 
  headline1, 
  headline2,
  lighttext, 
  topLine,
  topLine1,
  topLine2,
  start,
  img,
  alt,
  width ,
  height
}) => {

  const fadeLeft = {
    hidden: {opacity : 0, x:-100},
    visible: {opacity: 1, x:0}
  }

  return (
    <>
      <InfoSecwhite>
        <Container>
          <InfoRow imgStart={imgStart}>
            <InfoColumn>
              <TextWrapper>
              
                  <HeadingH4
                  
                  variants={fadeLeft}
                  initial='hidden'
                  animate='visible'
                  transition={{duration: 1}}
                  lighttext={lighttext}>{headline} <br/><br/>< IoFlashOutline style={{color: '#70983f', fontSize: '60px'}} /></HeadingH4>
                  <TopLine lightTopLine style={{color: '#c3d960'}}>{topLine}</TopLine>
                  <Subtitle lighttextDesc={lighttextDesc}>{description}</Subtitle>
                  
                  <HeadingH4 
                  variants={fadeLeft}
                  initial='hidden'
                  animate='visible'
                  transition={{duration: 1}}
                  lighttext={lighttext}>{headline1}<IoInfiniteOutline style={{color: '#70983f', fontSize: '60px'}}/></HeadingH4>
                  <TopLine lightTopLine style={{color: '#c3d960'}}>{topLine1}</TopLine>
                  <Subtitle lighttextDesc={lighttextDesc}>{description1}</Subtitle>
                  <HeadingH4
                  variants={fadeLeft}
                  initial='hidden'
                  animate='visible'
                  transition={{duration: 1}}
                  lighttext={lighttext}>{headline2}<IoRocketOutline style={{color: '#70983f', fontSize: '60px'}}/></HeadingH4>
                  <TopLine lightTopLine style={{color: '#c3d960'}}>{topLine2}</TopLine> 
                  <Subtitle lighttextDesc={lighttextDesc}>{description2}</Subtitle>
              </TextWrapper>
            </InfoColumn>
            <InfoColumn>
              <ImgWrapper start={start}>
                <Img src={img} alt={alt} width={width} height={height} />
              </ImgWrapper>
            </InfoColumn>
          </InfoRow>
        </Container>
      </InfoSecwhite>
    </>
  )
}

export default InfoSection3
