import styled from "styled-components";
import { motion } from "framer-motion";


export const InfoColumn = styled.div`
margin-bottom: 15px;
padding-right: 15px;
padding-left: 15px;
flex: 1;
max-width: 50%;
flex-basis: 50%;


@media screen and (max-width: 768px) {
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}
`;

export const ColumInfo = styled(motion.div)`
max-width: 540px;
border-radius: 7px;
grid-area: area1;
max-height: 500px;
display: inherit;


&:hover
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.4);
  background: '#D0BDBD';
  cursor: pointer;
  
  
}

@media screen and (max-width: 768px) {
  padding-bottom: 2px;
  max-width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 25px;
  max-height: auto;
}
`;
export const WraperVideo = styled(motion.div)`

padding-top: 0;
padding-bottom: 20px;
border-radius: 7px;
grid-area: area2;
max-height: 500px;
display: inherit;
border: 1px solid ${({ theme }) => theme.subtitle};
}

@media screen and (max-width: 768px) {
  padding-bottom: 2px;
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  margin: 25px;
  max-height: auto;
}
`;
export const  WraperImg1= styled(motion.div)`
max-width: 540px;
padding-top: 20px;
padding-bottom: 20px;

grid-area: area3;
max-height: 500px;
object-fit: cover;  

}

@media screen and (max-width: 768px) {
  padding-bottom: 2px;
  max-width: 100%;
  justify-content: center;
  padding: 25px;
  max-height: auto;
}
`;
export const  WraperImg2 = styled(motion.div)`
max-width: 540px;
padding-top: 20px;
padding-bottom: 20px;
grid-area: area4;
max-height: 500px;
object-fit: cover; 
}

@media screen and (max-width: 768px) {
  padding-bottom: 2px;
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  padding: 25px;
  max-height: auto;
}
`;

export const TopLine = styled.div`
color: ${({ theme }) => theme.subtitle};
font-size: 18px;
line-height: 16px;
letter-spacing: 1.4px; 
margin-bottom: 16px;
`;

export const HeadingModals = styled(motion.h1)`
text-align: center;
display: grid;
align-items: center;
margin-bottom: 24px;
line-height: 1.1;
font-size: 48px;
color: ${({ theme }) => theme.headigh4};
text-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;

`;

export const SubtitleModals = styled.p`
max-width: 440px;
margin-bottom: 35px;
font-size: 18px;

color: ${({ theme }) => theme.subtitle};
text-align: justify;
text-shadow: none;
font-weight: normal; 

`;

export const ImgWrapper = styled.div`
max-width: 555px;
display: flex;
justify-content: ${({start}) => (start ? 'flex-start' : 'flex-end')};

`;

export const ImgModal = styled(motion.img)`
padding-right: 0;
border: 0;
max-width: 100%;
vertical-align: middle;
display: inline-block;
max-height: 100%;
`;