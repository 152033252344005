import styled from "styled-components";
import { motion } from "framer-motion";

export const InfoSec = styled.div`
color: #fff;
padding: 20px 0;
background: ${({ theme }) => theme.secwhite};
padding-bottom: 60px;git 
`;

export const InfoRow = styled.div`
display: flex;
margin: 0 -15px -15px -15px;
flex-wrap: wrap;
align-items: center;
border-radius: 7px;
box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse' : 'row')};

@media screen and (max-width: 768px) {
  margin-top: 20%;
}
`;

export const InfoColumn = styled.div`

top: 0px;
left: 0px;
width: 50%;
height: 100%;
z-index: 1;
display: flex;
-moz-box-align: center;
align-items: center;
-moz-box-pack: center;
justify-content: center;
flex-direction: column;
}

@media screen and (max-width: 768px) {
  max-width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  
}
`;

export const TextWrapper = styled.div`
max-width: 540px;
padding-top: 0;
padding-bottom: 60px;
width: 100%;
display: grid;
text-align: center;


  @media screen and (max-width: 768px) {
    padding-bottom: 35px;
    max-width: 95%;
  
    
}
`;

export const TopLine = styled.div`
color: ${({lightTopLine}) => (lightTopLine ? '#35560c' : '#c3d960')};
font-size: 18px;
line-height: 16px;
letter-spacing: 1.4px;
margin-bottom: 16px;
`;

export const Heading = styled(motion.h1)`
margin-bottom: 24px;
font-size: 12em;
line-height: 1.2;
display: inline-block;
color: ${({ theme }) => theme.headigh4};
text-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;

@media screen and (max-width: 768px) {
  font-size: 10em;
}
`;

export const Subtitle = styled.p`
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
color: ${({ theme }) => theme.subtitle};
`;

export const ImgWrapper = styled.div`
top: 0px;
left: 0px;
width: 100%;
height: 100%;
z-index: 1;
display: flex;
-moz-box-align: center;
align-items: center;
-moz-box-pack: center;
justify-content: center;
flex-direction: column;
background: rgba(0, 0, 0, 0.5) none repeat scroll 0% 0%;
}

`;



export const Img = styled.img`
object-fit: cover;
object-position: -220px;
width: 100%;
height: 35em;
filter: blur(1px);
@media screen and (max-width: 768px) {
  display: none;
}

`;
export const Logo = styled.div`
cursor: pointer;
padding: 1rem;
align-items: center;


`;
