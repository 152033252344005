import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/HomePage/Home";
import GlobalStyle from "./globalStyles";
import Servicios from "./pages/Servicios/Servicios";
import ScrollToTop from "./components/ScrollToTop";
import Portafolio from "./pages/Portafolio/Portafolio";
import About from "./pages/About/About";
import Page404 from "./pages/Page404/Page404";
import Contactanos from "./pages/contac/contactanos";

import {ThemeProvider} from "styled-components";
import {useDarkMode} from "./components/Darkmode/useDarkmode";
import { lightTheme, darkTheme } from "./components/Darkmode/Themes"
import Toggle from "./components/Darkmode/Toggler"

function App() {

  const [theme, themeToggler] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : darkTheme;
  return (
    <ThemeProvider theme={themeMode}>
    <Router>
      <GlobalStyle />
      
      <Toggle theme={theme} toggleTheme={themeToggler} />
      <ScrollToTop />
      
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/servicios" element={<Servicios/>} />
        <Route path="/portafolio" element={<Portafolio />} />
        <Route path="/nosotros" element={<About/>} />
        <Route path="/contactanos" element={<Contactanos/>} />
        <Route path="*" element={<Page404/>} />
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
